import React, { useEffect } from 'react';
import { FieldLabel } from '@reservamos/elements';
import useCieloTDCContext from 'components/cielo/CieloTDCContext/useCieloTDCContext';
import Installments from 'components/purchase/Installments';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import usePurchase from 'hooks/store/usePurchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { getExpirationMonths, getExpirationYears } from 'utils/formRenderers';
import { getValidationFeedbackClass } from 'utils/formValidations';

const inputStyles =
  'form-input rounded border-none shadow-[0px_0px_9px_.5px_#E6E6E6] focus:border focus:border-solid focus:border-accent-regular focus:outline-none focus:shadow-[0px_0px_4px_2px] focus:shadow-accent-regular/20';

const Select = ({
  id,
  name,
  className = '',
  value = '',
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  options = [],
  isValid = false,
  hasError = false,
}) => {
  return (
    <div className="form-item-select w-full">
      <select
        id={id}
        name={name}
        className={`${className} ${inputStyles} ${getValidationFeedbackClass(isValid, hasError)}`}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    </div>
  );
};

const Input = ({
  id,
  name,
  className = '',
  value = '',
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  label = '',
  isValid = false,
  hasError = false,
}) => {
  return (
    <div className="grid gap-1 relative w-full">
      <FieldLabel inputId={id} text={label}>
        {label}
      </FieldLabel>
      <input
        id={id}
        name={name}
        className={`${className} ${inputStyles} ${getValidationFeedbackClass(isValid, hasError)}`}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

const CieloTDCForm = () => {
  const features = useWhitelabelFeatures();
  const envs = useWhitelabelEnvs();
  const purchase = usePurchase();
  const { accessToken, mountCieloTDCForm, form, isLoading } = useCieloTDCContext();
  const totalInCents = purchase.total * 100;

  useEffect(() => {
    mountCieloTDCForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className={`${isLoading && 'blur-sm'}`}>
      {/** (Start) Hidden inputs, required values by Cielo but not available for the user */}
      <input type="hidden" name="authEnabled" className="bpmpi_auth" value="true" />
      <input type="hidden" name="accessToken" className="bpmpi_accesstoken" value={accessToken} />
      <input
        type="hidden"
        name="orderNumber"
        className="bpmpi_ordernumber"
        value={purchase.token}
      />
      <input type="hidden" name="currency" className="bpmpi_currency" value={envs.cielo.currency} />
      <input type="hidden" name="amount" className="bpmpi_totalamount" value={totalInCents} />
      <input
        type="hidden"
        name="installments"
        className="bpmpi_installments"
        value={String(purchase.monthlySelectedPlan)}
      />
      <input
        type="hidden"
        name="merchantUrl"
        className="bpmpi_merchant_url"
        value={envs.cielo.merchantUrl}
      />
      <input type="hidden" name="paymentMethod" className="bpmpi_paymentmethod" value="credit" />
      {/** (End) Hidden inputs, required values by Cielo but not available for the user */}

      {/** (Start) Dynamic inputs, values that will be provided by the user */}
      <div className="grid gap-16">
        {features.INSTALLMENTS_ENABLED && <Installments />}
        <div className="grid gap-8">
          <div className="flex gap-7 flex-col md:flex-row md:gap-3">
            <Input
              id="holderName"
              name="holderName"
              label="Card Holder Name"
              value={form.values.holderName}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.holderName && form.touched.holderName)}
              isValid={Boolean(!form.errors.holderName && form.touched.holderName)}
            />
            <Input
              id="cardNumber"
              name="cardNumber"
              label="Card Number"
              className="bpmpi_cardnumber"
              value={form.values.cardNumber}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.cardNumber && form.touched.cardNumber)}
              isValid={Boolean(!form.errors.cardNumber && form.touched.cardNumber)}
            />
          </div>
          <div className="flex gap-7 flex-col md:flex-row md:gap-3">
            <div className="grid gap-1 relative w-full">
              <FieldLabel inputId="expirationDate" text="Expiration Date" />
              <fieldset id="expirationDate" className="flex gap-2">
                <Select
                  id="expirationMonth"
                  name="expirationMonth"
                  label="Month"
                  className="bpmpi_cardexpirationmonth"
                  options={getExpirationMonths()}
                  value={form.values.expirationMonth}
                  disabled={isLoading}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  hasError={Boolean(form.errors.expirationMonth && form.touched.expirationMonth)}
                  isValid={Boolean(!form.errors.expirationMonth && form.touched.expirationMonth)}
                />
                <Select
                  id="expirationYear"
                  name="expirationYear"
                  label="Year"
                  className="bpmpi_cardexpirationyear"
                  options={getExpirationYears().map((year) => ({
                    display: year,
                    value: year,
                  }))}
                  value={form.values.expirationYear}
                  disabled={isLoading}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  hasError={Boolean(form.errors.expirationYear && form.touched.expirationYear)}
                  isValid={Boolean(!form.errors.expirationYear && form.touched.expirationYear)}
                />
              </fieldset>
            </div>
            <Input
              id="cvv2"
              name="cvv2"
              label="CVV"
              value={form.values.cvv2}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.cvv2 && form.touched.cvv2)}
              isValid={Boolean(!form.errors.cvv2 && form.touched.cvv2)}
            />
          </div>
        </div>
      </div>
      {/** (End) Dynamic inputs, values that will be provided by the user */}
    </form>
  );
};

export default CieloTDCForm;
