import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import valid from 'card-validator';
import { setAlert } from 'actions';
import { cardPaymentAttempt } from 'actions/payment';
import cieloEngine from 'payments/core/engines/cielo';
import useScript from 'hooks/useScript';
import usePurchase from 'hooks/store/usePurchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { validateCardFields } from 'utils/formValidations';
import { getExpirationMonths, getExpirationYears } from 'utils/formRenderers';
import CieloTDCContext from './CieloTDCContext';

/**
 * CieloTDCProvider component.
 * Manages the Cielo 3DS integration and provides context for Cielo credit card payments.
 */
const CieloTDCProvider = ({ children, config }) => {
  const dispatch = useDispatch();
  const purchase = usePurchase();
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef();
  const formik = useFormik({
    initialValues: {
      holderName: '',
      cardNumber: '',
      expirationMonth: getExpirationMonths()[0].value,
      expirationYear: String(getExpirationYears()[0]),
      cvv2: '',
      cardBrand: '',
    },
    onSubmit: () => {
      if (!window.bpmpi_authenticate) {
        dispatch(setAlert('cielo.reload', 'error'));
      }
      setIsLoading(true);
      window.bpmpi_authenticate();
    },
    validate: (values) => {
      const errors = validateCardFields(values);
      if (!errors.cardNumber) {
        const cardBrand = valid.number(values.cardNumber).card.type;
        formik.setFieldValue('cardBrand', cardBrand);
      }
      return errors;
    },
    validateOnChange: false,
  });
  const [accessToken, setAccessToken] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [hasErrorFetchingToken, setHasErrorFetchingToken] = useState(false);
  const { hasError: hasErrorLoading3dsScript } = useScript(config.threeDSScriptSrc, {
    shouldLoadScript: config.enabled && accessToken,
    removeOnUnmount: true,
    onUnmount: () => {
      const cardinalScripts = document.querySelectorAll('script[src*="cardinalcommerce.com"]');
      cardinalScripts.forEach((script) => {
        script.remove();
      });
      formik.resetForm();
      formikRef.current = formik;

    },
  });
  const monthlySelectedPlanRef = useRef();
  formikRef.current = formik;
  monthlySelectedPlanRef.current = purchase.monthlySelectedPlan;

  const setBpmpiConfig = () => {
    window.bpmpi_config = () => {
      return {
        onReady: () => {
          setIsReady(true);
        },
        onSuccess: (event) => {
          const formValues = formikRef.current.values;

          const formValuesAreInvalid = !formValues.cardBrand;
          if (formValuesAreInvalid) {
            return;
          }
          const cardValues = {
            ...formValues,
            monthlySelectedPlan: monthlySelectedPlanRef.current,
          };
          const threeDSValidationData = {
            cavv: event.Cavv,
            xid: event.Xid,
            eci: event.Eci,
            version: event.Version,
            referenceId: event.ReferenceId,
          };
          dispatch(cardPaymentAttempt(purchase.token, cardValues, { threeDSValidationData }));
          setIsLoading(false);
        },
        onFailure: () => {
          dispatch(
            setAlert(
              'a',
              'error',
              'Ocurrió un error al procesar el pago. Revisa los datos de tu tarjeta y vuelve a intentarlo.',
              '',
            ),
          );
          setIsLoading(false);
        },
        onUnenrolled: () => {
          dispatch(
            setAlert(
              'a',
              'error',
              'Ocurrió un error al procesar el pago. Revisa los datos de tu tarjeta y vuelve a intentarlo.',
              '',
            ),
          );
          setIsLoading(false);
        },
        onDisabled: () => {
          dispatch(
            setAlert(
              'a',
              'error',
              'Ocurrió un error al procesar el pago. Revisa los datos de tu tarjeta y vuelve a intentarlo.',
              '',
            ),
          );
          setIsLoading(false);
        },
        onError: () => {
          dispatch(
            setAlert(
              'a',
              'error',
              'Ocurrió un error al procesar el pago. Revisa los datos de tu tarjeta y vuelve a intentarlo.',
              '',
            ),
          );
          setIsLoading(false);
        },
        onUnsupportedBrand: () => {
          dispatch(
            setAlert(
              'a',
              'error',
              'Ocurrió un error al procesar el pago. Revisa los datos de tu tarjeta y vuelve a intentarlo.',
              '',
            ),
          );
          setIsLoading(false);
        },
        Environment: config.environment,
        Debug: config.debug,
      };
    };
  };

  const fetch3DSToken = async () => {
    try {
      const token = await cieloEngine.get3DSToken();
      setAccessToken(token);
    } catch (err) {
      setHasErrorFetchingToken(true);
    }
  };

  /** Flujo para carga inicial de 3DS
   *
   * 1. Declarar función bpmpi_config
   * 2. Fetch de token 3DS
   * 3. Renderizar el formulario de cielo con un loading state y el campo del token oculto
   * 4. Cargar el script de 3DS
   * 5. Activar el formulario de cielo para que se pueda interactuar
   */
  const mountCieloTDCForm = () => {
    setBpmpiConfig();
    fetch3DSToken();
  };

  const submitForm = async () => {
    formik.submitForm();
  };

  const contextValue = {
    accessToken,
    hasError: hasErrorFetchingToken || hasErrorLoading3dsScript,
    submitForm,
    mountCieloTDCForm,
    form: formik,
    isLoading: isLoading || !isReady,
  };

  return <CieloTDCContext.Provider value={contextValue}>{children}</CieloTDCContext.Provider>;
};

CieloTDCProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

/**
 * CieloTDCProviderGuard component.
 * Wraps the CieloTDCProvider component and ensures it is only rendered if the Cielo engine is enabled.
 * If the Cielo engine is not enabled, it render a context with empty values.
 */
const CieloTDCProviderGuard = ({ children }) => {
  const env = useWhitelabelEnvs();
  if (env.cielo.enabled) {
    return <CieloTDCProvider config={env.cielo}>{children}</CieloTDCProvider>;
  }
  return (
    <CieloTDCContext.Provider
      value={{
        accessToken: '',
        hasError: false,
        submitForm: () => {},
        mountCieloTDCForm: () => {},
        form: {},
        isLoading: false,
      }}
    >
      {children}
    </CieloTDCContext.Provider>
  );
};

CieloTDCProviderGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CieloTDCProviderGuard;
